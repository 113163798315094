<template>
    <div class="list-activity-index-page">
        <DashCardRouter title="Actividad reciente" :icon="$assets.black.recentActivity" />
        <ul v-if="activities.length" class="list-activity-index-page__list">
            <li v-for="(activity, index) in activities" :key="index" class="list-activity-index-page__item">
                <DashCardActivity :info="activity" />
            </li>
        </ul>
        <div v-else>
            <MiscNoResults mode="activities" :title="$lang.pages.dashboardActividad.no_activity" />
            <div class="no-activity">
                <NuxtLink
                    no-prefetch
                    class="no-activity__offer"
                    :to="`/${$lang.routes.share}/${$lang.routes.offer}`"
                    >{{ $lang.pages.dashboardActividad.publish_offer }}</NuxtLink
                >
                <NuxtLink
                    no-prefetch
                    class="no-activity__coupon"
                    :to="`/${$lang.routes.share}/${$lang.routes.coupon}`"
                    >{{ $lang.pages.dashboardActividad.publish_coupon }}</NuxtLink
                >
            </div>
        </div>
        <ButtonLoadMore
            v-if="fetchButton.show"
            :loading="fetchButton.loading"
            :handler="() => getMoreActivities()"
        />
    </div>
</template>

<script lang="ts" setup>
import { useFallbackStore } from '~/store/fallback'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

const Route = useRoute()
const FallbackStore = useFallbackStore()
const { $lang } = useNuxtApp()

const params = reactive({
    per_page: 10,
    page: 1,
})
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const getActivities = () => {
    const response = $fetch<Api.Responses.General.IndividualModel<Models.Activity>>(
        endpoints.general.lastActivities.path.replace('_ID_', String(Route.params.user || '')),
        {
            headers: buildHeaders(),
            baseURL,
            method: 'GET',
            params,
        },
    )

    params.page++

    return response
}

const { data: responseData, error } = await useAsyncData('dashboard-user-activities', () =>
    getActivities().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: 404,
        message: responseData.value?.feedback || error.value?.message || 'Error data',
    })
}

const activities = ref(responseData.value.data.results)
const fetchButton = reactive({
    loading: false,
    show:
        responseData.value.data.meta.total > 0 &&
        responseData.value.data.meta.current_page < responseData.value.data.meta.last_page,
})

const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.dashboardUserIndex.title_seo.replace(
    '{{name}}',
    FallbackStore.userProfileFallback.data.user.username,
)
const description = $lang.pages.dashboardUserIndex.description_seo.replace(
    '{{name}}',
    FallbackStore.userProfileFallback.data.user.username,
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const getMoreActivities = async () => {
    fetchButton.loading = true

    const { feedback, data } = await getActivities()

    if (feedback === 'data_success') {
        activities.value = [...activities.value, ...data.results]

        if (data.meta.current_page >= data.meta.last_page) {
            fetchButton.show = false
        }
    }

    fetchButton.loading = false
}
</script>

<style lang="postcss" scoped>
.list-activity-index-page {
    @apply bg-white p-2 xs:rounded-xl;
    &__item:not(:last-of-type) {
        @apply border-b;
    }
    .no-activity {
        @apply mb-5 mt-5 flex w-full flex-wrap items-center justify-center gap-2;
        &__offer,
        &__coupon {
            @apply rounded-xl px-10 py-3 text-white transition duration-200 lg:hover:brightness-110;
        }
        &__offer {
            @apply bg-site-secondary;
        }
        &__coupon {
            @apply bg-site-primary;
        }
    }
}
</style>
